import React from "react";
import aeroflex from "../homeimg/aeroflex.png";
import zeco from "../homeimg/zeco.png";
import phoenix from "../homeimg/phoenix.png";
import agilon from "../homeimg/agilon.png";
import trf from "../homeimg/trf.png";
import lubi from "../homeimg/lubi.png";
import advance from "../homeimg/advance.png";
import kflex from "../homeimg/kflex.png";
import belimo from "../homeimg/belimo.png";
import mechmarkk from "../homeimg/mechmaark.png";
import acrenof from "../homeimg/acreconf.png";
import ventconf from "../homeimg/ventconf.png";

// import elgi from "../partnerPharmaConnect/elgi.png";
// import kirloskar from "../partnerPharmaConnect/kirloskar.png";
// import climcone from "../partnerPharmaConnect/climcone.png";
// import vts from "../partnerPharmaConnect/vts.png";
// import shk from "../partnerPharmaConnect/shk.png";
// import vasu from "../partnerPharmaConnect/vasu.png";
// import trueStar from "../partnerPharmaConnect/truestar.png";
// import revo from "../partnerPharmaConnect/revo.png";
// import casilica from "../partnerPharmaConnect/csilica.png";
// import advanceValues from "../partnerPharmaConnect/advanceValues.png";
// import aditya from "../partnerPharmaConnect/aditya.png";
// import ae from '../partnerPharmaConnect/ae.png'
// import bryair from '../partnerPharmaConnect/bryair.png'

import "./partners.css";
const Partners = () => {
  return (
    <>
      <div id="partners" className="container">
        <div className="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
          <h1
            style={{ textDecoration: "none " }}
            className="mb-6 font-semibold md:text-2xl md:leading-tight text-gray-800 dark:text-neutral-200"
          >
            Our Annual Partners
          </h1>
        </div>

        <div
          id="partnerslogo"
          className="max-w-[95rem] d-flex px-4 py-10 sm:px-6 lg:px-8 lg:py-14 "
        >
          <h2 className="text-center mt-5 mb-5 ">Gold Partners</h2>
          <div
            id="goldpartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={aeroflex} alt="aeroflex" />
            </div>

            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={zeco} alt="zeco" />
            </div>

            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={phoenix} alt="zeco" />
            </div>

            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={agilon} alt="agilon" />
            </div>

            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={advance} alt="advance" />
            </div>
          </div>
          <hr />
          <h2 className="text-center mt-5 mb-5">Silver Partners</h2>
          <div
            id="silverpartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
           
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={trf} alt="trf" />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={belimo} alt="belimo" />
            </div>
          
          </div>
          <hr />
          <h2 className="text-center mt-5 mb-5">Bronze Partners</h2>
          <div
            id="bronzepartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={mechmarkk} alt="mechmarkk" />
            </div>

            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img src={kflex} alt="kflex" />
            </div>

          
          </div>
          <hr />
        </div>
        {/* <div className="sm:w-1/2 xl:w-1/3 mx-auto mt-5 text-center mb-6 md:mb-12">
          <h1
            style={{ textDecoration: "none " }}
            className="mb-6 font-semibold md:text-2xl md:leading-tight text-gray-800 dark:text-neutral-200"
          >
            Our Pharma Connect Partners
          </h1>
        </div> */}

        <div
          id="partnerslogo"
          className="max-w-[95rem] d-flex px-4 py-10 sm:px-6 lg:px-8 lg:py-14 "
        >
          {/* <h2 className="text-center mt-5 mb-5 ">Title Partners</h2> */}
          {/* <div
            id="goldpartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6">
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={elgi}
                alt="kehems"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
          </div> */}
          {/* <hr />
          <h2 className="text-center mt-5 mb-5">Gold Partners</h2>
          <div
            id="silverpartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={kirloskar}
                alt="blaze"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
          </div> */}
          {/* <hr />
          <h2 className="text-center mt-5 mb-5">Registration Partners</h2>
          <div
            id="bothpartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={climcone}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
          </div>
          <hr /> */}
          {/* <h2 className="text-center mt-5 mb-5">Technology Partners</h2>
          <div
            id="bothpartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={vts}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={shk}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={vasu}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={trueStar}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
          </div>
          <hr /> */}
          {/* <h2 className="text-center mt-5 mb-5">Booth Partners</h2>
          <div
            id="bothpartner"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={revo}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={casilica}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={advanceValues}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={aditya}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={ae}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
            <div className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800">
              <img
                src={bryair}
                alt="brydri"
                style={{ width: "180px", height: "90px" }}
              />
            </div>
          </div>
          <hr /> */}
        </div>
      </div>

      <div id="events" className="container">
        <div className="sm:w-1/2 xl:w-1/3 mx-auto text-center mb-6 md:mb-12">
          <h1
            style={{ textDecoration: "none" }}
            className="mb-4 font-semibold md:text-2xl md:leading-tight text-gray-800 dark:text-neutral-200"
          >
            Our Events
          </h1>
        </div>

        <div
          id="eventslogo"
          className="max-w-[95rem] d-flex px-4 py-10 sm:px-6 lg:px-8 lg:py-14 "
        >
          <div
            id="allevents"
            className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-3 lg:gap-6"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800"
            >
              <img src={acrenof} alt="acrenof" />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="p-3 md:p-7 bg-gray-100 rounded-lg dark:bg-neutral-800"
            >
              <img src={ventconf} alt="ventconf" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
