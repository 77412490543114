import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
const localizer = momentLocalizer(moment);

const MyEventCalendar = () => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        setCurrentDate(new Date());
    }, []);

    const events = [
        {
            start: new Date(2025, 2, 1, 10, 0), // March 1, 2025, 10:00 AM
            end: new Date(2025, 2, 1, 12, 0), // March 1, 2025, 12:00 PM
            title: 'Project Management ',
            description: 'Project Management ',
            path: '/event-1'
        },
        {
            start: new Date(2025, 2, 21, 10, 0), // March 21, 2025, 10:00 AM
            end: new Date(2025, 2, 21, 12, 0), // March 21, 2025, 12:00 PM
            title: 'Advancements in Cooling Tower design and Technology',
            description: 'Advancements in Cooling Tower design and Technology',
            path: '/event-2'
        },
        {
            start: new Date(2025, 2, 5, 10, 0), // March 21, 2025, 10:00 AM
            end: new Date(2025, 2, 5, 12, 0), // March 21, 2025, 12:00 PM
            title: 'She Alliance',
            description: 'Leading Women in Consulting Architecture, Shaping the Future of HVAC Industries',
            path: '/event-4'
        },
        {
            start: new Date(2025, 1, 7, 10, 0), // February 7, 2025, 10:00 AM
            end: new Date(2025, 1, 7, 12, 0), // February 7, 2025, 12:00 PM
                title: 'Workshop on kitchen Ventilation',
            description: 'Workshop on kitchen Ventilation',
            path: '/event-3'
        }
        
    ];
    

    const handleSelectEvent = (event) => {
            if (event.pdfUrl) {
                window.open(event.pdfUrl, '_blank');
            } else if (event.path) {
                window.open(event.path, '_blank');
            }
        
    };

    const handleClose = () => setShowModal(false);

    const formatDateRange = (start, end) => {
        return `${moment(start).format('MMMM D, YYYY h:mm A')} - ${moment(end).format('h:mm A')}`;
    };

    return (
        <div style={{ height: '500px'}} className='container'>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: '100%' }}
                date={currentDate}
                onNavigate={date => setCurrentDate(date)}
                views={{ month: true, week: true, day: true }}
                onSelectEvent={handleSelectEvent}
            />

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{selectedEvent ? selectedEvent.title : 'Modal title'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedEvent ? (
                        <>
                            <p>{selectedEvent.description}</p>
                            <p><strong>Date:</strong> {formatDateRange(selectedEvent.start, selectedEvent.end)}</p>
                        </>
                    ) : (
                        '...' 
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MyEventCalendar;
