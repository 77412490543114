import React, { useEffect } from "react";
// import delhi from "./delhi.webp";
// import tree from "./tree.jpg";
// import homeslide from "./homeslide.webp";
import homeslide from "./homeslide25-26.webp";
import { Link } from "react-router-dom";
import "./home.css";
import aboutimg from "./aboutimg.webp";
import MyEventCalendar from "./EventCalander";
import CwcMembers from "./CwcMembers";
import adveng from "./adveng.webp";
import popprd from "./popprd.webp";
import plan from "./plan.webp";
import certificate from "./certificate.webp";
import target from "./target.png";
import activities from "./activities.png";
import president from "./homeimg/president (2).png";
import academy from "./homeimg/academy.png";
import knowledge from "./homeimg/knowledge.png";
import newspaper from "./homeimg/newspaper.png";
import membership from "./homeimg/membership.png";
import Membership from "./Membership";
import Partners from "./partners/Partners";

import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - Home";



  }, []);

  return (

    <>
      <div id="carouselExampleCaptions" className="carousel slide">
            <Helmet>

            
<title>Delhi Chapter of Ishrae </title>
<meta name="description" content="DC ISHRAE, the Indian Society of Heating, Refrigerating & Air-conditioning Engineers was founded in 1981 in New Delhi by a group of ten eminent HVAC&R professionals."/>
<meta name="keywords" content="Delhi Chapter of ISHRAE, HVAC, HVAC R, ISHRAE, ASHRAE" />
<link rel="canonical" href="https://dcishrae.in/" />
<meta property="og:type" content="website" />
<meta property="og:title" content="Delhi Chapter of Ishrae" />
<meta property="og:description" content="DC ISHRAE, the Indian Society of Heating, Refrigerating & Air-conditioning Engineers was founded in 1981 in New Delhi by a group of ten eminent HVAC&R professionals." />
<meta property="og:url" content="https://dcishrae.in/" />
<meta property="og:site_name" content="Delhi Chapter of Ishrae" />
<meta property="og:image" content="../navbar/logo.png"/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="Delhi Chapter of Ishrae" />
<meta name="twitter:description" content="DC ISHRAE, the Indian Society of Heating, Refrigerating & Air-conditioning Engineers was founded in 1981 in New Delhi by a group of ten eminent HVAC&R professionals." />
<meta name="twitter:image" content="../navbar/logo.png" />

                
            </Helmet>

        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
        
        </div>

        <div className="carousel-inner">         
          <div className="carousel-item active">
            <img src={homeslide} className="d-block w-100" alt="..." />
            <div className="carousel-caption d-md-block">
              <h1>COMMITTED TO SERVE THROUGH</h1>
              <p>THE POWER OF KNOWLEDGE</p>
            </div>
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
      

      <div id="about" className="container">
        <div className="about">
          <div className="card">
            <img src={aboutimg} alt="ISHRAE" />
            <div className="cardcontent">
              <h2>About Delhi chapter of ISHRAE</h2>
              <p>
                The Delhi Chapter of the Indian Society of Heating,
                Refrigerating and Air Conditioning Engineers (ISHRAE) is a
                vibrant hub for professionals, researchers, and students in the
                HVAC&R and building services industry. Established as a
                cornerstone of ISHRAE’s nationwide network, the Delhi Chapter is
                committed to advancing knowledge, fostering innovation, and
                promoting sustainable practices in building systems and
                environmental control.
                <br />
              
              </p>
              <Link to="/about">Read More</Link>
            </div>
          </div>

          <div className="card2">
            <div className="card2head">
              <h2>ISHRAE Objectives</h2>
            </div>
            <div className="objectivecards">  
              <div className="objectivecard">
                <img src={target} alt="ISHRAE" />
                <div className="objectivecardcontent">
                  <h4>ISHRAE MISSION</h4>
                  <p>
                    To promote the goals of the Society for the benefit of the
                    general public. Towards this objective, the Chapters of the
                    Society participate in, and organize, activities to protect
                    the Environment, improve Indoor Air Quality, help Energy
                    ....
                  </p>
                </div>
              </div>
              <div className="objectivecard">
                <img src={activities} alt="ISHRAE" />
                <div className="objectivecardcontent">
                  <h4>ACTIVITIES</h4>
                  <p>
                    As part of its objectives to promote the interests of the
                    HVAC&R Industry, ISHRAE is involved in various activities.
                    ISHRAE reaches out to all its members and seeks their active
                    participation & involvement in all the Events/Programs
                    organized by the society...
                  </p>
                </div>
              </div>
            </div>
            <button>
              <a href="/">View More</a>
            </button>
          </div>
        </div>

        <div className="courses">
          <div className="course">
            <img src={adveng} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>DIPLOMA COURSE-IIE </h5>
              <p>Advanced Engineering Diploma Course</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
          <div className="course">
            <img src={popprd} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>SHOP NOW </h5>
              <p>Popular Products</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
          <div className="course">
            <img src={plan} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>MEMBERSHIP PLAN</h5>
              <p>Benefits of joining ISHRAE</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
          <div className="course">
            <img src={certificate} alt="DC ISHRAE" />
            <div className="coursecontent">
              <h5>ICP COURSES </h5>
              <p>Certification program by ISHRAE</p>
              <button>
                <a href="https://iie.ishrae.in/" target="_blank">
                  Know More
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container" id="benefits">
        <div className="benefitshead">
          <h3>ISHRAE</h3>
          <span>BENEFITS OF JOINING DELHI CHAPTER OF ISHRAE</span>
          <p>A Premiere Technical Society Exclusively For Ac & R Community</p>
        </div>
        <div className="benefitscontent">
          <p>
            <i class="fa-solid fa-arrow-right"></i>Membership For Individual
            Only, No corporate Membership
          </p>
          <p>
            <i class="fa-solid fa-arrow-right"></i>A Premiere Technical Society
            Exclusively For AC & R Community
          </p>
          <p>
            <i class="fa-solid fa-arrow-right"></i>Provides An International
            Exposure through International Associates
          </p>
          <button>
            <a href="">Know More</a>
          </button>
        </div>
      </div>

      <div id="message" className="container">
        <div className="messageimg">
          <img src={president} alt=" DC ISHRAE" />
        </div>
        <div className="messagecontent">
          <h4 className="text-center mt-2 mb-0">Waliullah Siddiqui</h4>
          <h6 className="text-center mt-0 mb-0">President Society Year 2025-2026</h6>
          <h6 className="text-center mt-0 mb-2">Delhi Chapter of ISHRAE</h6>
          <p style={{textAlign: "justify"}}>
          Dear Team DCI,
            <br />It is a matter of  pleasure for me to greet and welcome all elected DCI office bearers, Chapter Working Committee (CWC) members and extended CWC members for the year 2025-2026. <br/>Indeed We have a team which has a combination of experience, talent, dedication and enthusiasm and surely it has potential to take Delhi Chapter of ISHRAE to greater heights and make it best chapter in this  year.<br/>As it is apparent from its cover the purpose of this booklet is to consolidate at one place our chapter development plans, targets given by Head Quarter, our  planning & strategies to achieve it. All our members are requested to go through it, understand and contribute as per the responsibilities assigned in various committees.<br/>We will have at least one physical meeting of CWC every month and a zoom call meeting as required for reviewing the chapter activities progress during the course of year.<br/>We will also be interacting on daily basis on our WhatsApp group handle DCI CWC 2025-2026.
            You can also personally reach out to me  any time with your plans and ideas during this year.<br/>Let us join hands in making our chapter best in all activities like membership, programs, Student , K-12, Youth, Women, advocacy,  Education, Refrigeration and honors & awards.<br/>Our secretariate team consisting of  Mr Tapas Kushwaha (Executive Secretary), Ms Reena Bahuguna (Assistant Secretary) ,Mr Arvind Kumar (Senior Executive) , Mr Ankit kumar (Accounts Executive)  , Mr Nakul Badjatiya (Office Executive) and Mr Saurabh Kumar (Office executive)  are backbone of DCI operations and shall be supporting us in all activities.<br/>I also would like to bring to your notice that  ISHRAE has total 61 chapter and sub-chapters today spread all over India and Overseas but Delhi Chapter has the distinction of being the mother chapter of ISHRAE and with 1741 active members making it one of the largest ISHRAE chapters.<br/>So  it is indeed a matter of  great Pride for all of us to be associated with Delhi Chapter of ISHRAE (DCI)
            Let us “serve through the Power of Knowledge” and together bring glory and laurels to our chapter.
            Congratulations and best wishes.

          </p>
        </div>
      </div>
      <div className="container" id="mission">
        <div className="missioncontent">
          <h2>DCI Cup</h2>
          <p>
            The lndian Society of Heating, Refrigerating and Air Conditioning
            Engineers (ISHRAE)
          </p>
          <div className="missioncards">
            <div className="missioncard">
              <img src={academy} alt="DC ISHRAE" />
              <h4>Education and Certification</h4>
            </div>
            <div className="missioncard">
              <img src={newspaper} alt="DC ISHRAE" />
              <h4>Publication & Resources</h4>
            </div>
            <div className="missioncard">
              <img src={knowledge} alt="DC ISHRAE" />
              <h4>Knowledge Bank</h4>
            </div>
            <div className="missioncard">
              <img src={membership} alt="DC ISHRAE" />
              <h4>Membership</h4>
            </div>
          </div>
        </div>
        <div className="missionyt">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/I86V2jMeiBQ?si=CgCno1EBQjF7haNF"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </div>

      <br />
      <Membership />

      <div className="container" id="calander">
        <div className="calanderhead">
          <h2 style={{textAlign: "center"}}>UPCOMING EVENTS</h2>
        </div>
        <div className="calanderContainer">
          <MyEventCalendar />
        </div>
      </div>

      <div className="container">
        <CwcMembers />
      </div>
      <div className="container">
        <Partners />
      </div>
    
    </>
  );
};

export default Home;
