import React, { useEffect } from 'react'
import event4 from './eventImg/event-4.webp'

const Event4 = () => {
     useEffect(() => {
        document.title = "She Alliance";
      }, []);
  return (
    <>
    <div className='eventheading'>

<h1  className='text-center'>5th March 2025</h1>
<h3 className='text-center'>She Alliance <br/>Leading Women in Consulting & Architecture: Shaping the Future of HVAC Industries</h3>
</div>
   <div className='container' id='calanderevent'>
<img src={event4} alt=''/>
<button className='mb-4'><a href='https://ishraehq.in/Admin/CalenderEventMaster/Registration_new/XnqxfP2Q8os=' target='_blank'>Register Now </a></button>
    </div>
    </>
  )
}

export default Event4