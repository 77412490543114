import React, {useEffect} from "react";
import "./cwc.css";
import varunjain from './cwcimg/varun-jain.png'
import ravishankar from './cwcimg/ravi-shankar.png'
import rajnish from './cwcimg/rajnish-agarwal.png'
// import pradeep from './cwcimg/pradeep.png'
// import gauravVasudev from './cwcimg/gaurav-vasudev.png'
import robin from './cwcimg/robin-chauhan.png'
// import priyankaJain from './cwcimg/priyanka-jain.png'
import sheeba from './cwcimg/sheeba-siddiqui.png'
import amitKaushik from './cwcimg/amit-kaushilk.png'
import ruchiGrover from './cwcimg/ruchi-grover.png'
// import sanjeevSeth from './cwcimg/sanjeev.png'
import sandeepKohli from './cwcimg/sandeep-kohli.png'
// import pratimaSingh from './cwcimg/pratima-singh.png'
import dinesh from './cwcimg/dinesh.png'
// import sushilKumar from './cwcimg/sushil-kumar.png'
// import gauravShorey from './cwcimg/gourav-shoray.png'
// import kartikSinghal from './cwcimg/kartik-singhal.png'
// import praveenThakur from './cwcimg/praveen-thakur.png'
import vaibhavGupta from './cwcimg/vaibhav-gupta.png'
import shurtiGoel from './cwcimg/shruti-goel.png'
import pkGoel from './cwcimg/pk-goel.png'
// import pareshMishra from './cwcimg/paresh-mishra.png'
import akshatbhtnagar from './cwcimg/akshat-bhtnagar.png'
// import mdAdnan from './cwcimg/md-adnan.png'
// import anubhaGoel from './cwcimg/anubha-goel.png'
import gauravMathur from './cwcimg/gaurav-mathur.png'
import ashishJain from './cwcimg/ashish-jain.png'
import abhishekjain from './cwcimg/abhishek-jain.png'
import priyankaKulshreshta from './cwcimg/priyanka.png'
// import ashishGupta from './cwcimg/ashish-gupta.png'
// import priyankGarg from './cwcimg/priyank-garg.png'
import sandeepGoel from './cwcimg/sandeep-goel.png'
import waliullahSiddiqui from './cwcimg/waliullah-siddiqui.png'
import samtaBjaj from './cwcimg/samta-bjaj.png'
import sukhdaTandon from './cwcimg/sukhda-tandon.png'
import yasha from './cwcimg/yasha-chaudhary.png'
import daanish from './cwcimg/danish.png'
import adnanAbid from './cwcimg/adnan-abid.png'
import sandeepTaparia from './cwcimg/sandeep-taparia.png'
import mdwasiullah from './cwcimg/mdwasiullah.png'


import { Helmet } from "react-helmet";




const CWC = () => {
  useEffect(() => {
    document.title = "Delhi chapter of ISHRAE - CWC Members";
  }, []);

  return (

    <>

<div class="cwccontainer" id="cwccontainer2" >


<Helmet>
<title>CWC Members - DC ISHRAE </title>
<meta name="description" content="CWC Members - DCI CHAPTER WORKING COMMITTEE 2024-25"/>
<meta name="keywords" content=" ISHRAE CWC members, HVAC&R professionals, ASHRAE, HVAC sciences, Delhi Chapter of ISHRAE, ISHRAE chapters" />
<link rel="canonical" href="https://dcishrae.in/about-cwc" />
<meta property="og:type" content="website" />
<meta property="og:title" content="CWC Members - DC ISHRAE" />
<meta property="og:description" content="CWC Members - DCI CHAPTER WORKING COMMITTEE 2024-25" />
<meta property="og:url" content="https://dcishrae.in/about-cwc" />
<meta property="og:site_name" content="DC ISHRAE PRESIDETNS" />
<meta property="og:image" content="https://dcishrae.in/static/media/sandeep-goel.70756061aa1d9395de3f.png"/>
<meta name="twitter:card" content="summary" />
<meta name="twitter:title" content="CWC Members - DC ISHRAE" />
<meta name="twitter:description" content="CWC Members - DCI CHAPTER WORKING COMMITTEE 2024-25" />
<meta name="twitter:image" content="https://dcishrae.in/static/media/sandeep-goel.70756061aa1d9395de3f.png" />

</Helmet>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={ravishankar} alt="Mr. Ravi Shankar Kumar" />
            </div>
            <div class="contentBx">
              <h4>Mr. Ravi Shankar Kumar</h4>
              <h5>RD North 1</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={waliullahSiddiqui} alt="Waliullah Siddiqui" />
            </div>
            <div class="contentBx">
              <h4>Mr. Waliullah Siddiqui</h4>
              <h5>President</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sandeepGoel} alt="Sandeep Goel" />
            </div>
            <div class="contentBx">
              <h4>Mr. Sandeep Goel</h4>
              <h6>Immediate</h6>
              <h5>Past President</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sukhdaTandon} alt="sukhda tandon" />
            </div>
            <div class="contentBx">
              <h4>Ms. Sukhda Tandon</h4>
              <h5>President Elect</h5>
            </div>
          </div>
        </div>
        
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={ruchiGrover} alt="ruchi grover" />
            </div>
            <div class="contentBx">
              <h4>Dr. Ruchi Grover</h4>
              <h5>Secretary</h5>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={robin} alt="Robin Chauhan" />
            </div>
            <div class="contentBx">
              <h4>Mr. Robin Chauhan</h4>
              <h5>Treasurer</h5>
            </div>
          </div>
        </div>

        </div>

      <div className="cwccontainerhead">
        <h1>Cwc Members</h1>
        <h3>Delhi Chapter of ISHRAE working committee 2025-26</h3>
      </div>
      <div class="cwccontainer ">
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={varunjain} alt=" Varun Jain" />
            </div>
            <div class="contentBx">
              <h4>Dr. Varun Jain</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={rajnish} alt=" Rajnish Aggarwal " />
            </div>
            <div class="contentBx">
              <h4>Mr. Rajnish Aggarwal</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={samtaBjaj} alt="Samta Bajaj" />
            </div>
            <div class="contentBx">
              <h4>Ms. Samta Bajaj</h4>
            </div>
          </div>
        </div>

        {/* <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={gauravVasudev} alt="Gaurav Vasudev" />
            </div>
            <div class="contentBx">
              <h4>Mr. Gaurav Vasudev</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={robin} alt="Robin Chauhan" />
            </div>
            <div class="contentBx">
              <h4>Mr. Robin Chauhan</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={priyankaJain} alt="Priyanka Jain" />
            </div>
            <div class="contentBx">
              <h4>Ms. Priyanka Jain</h4>
            </div>
          </div>
        </div> */}

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sheeba} alt="Sheeba Siddiqui" />
            </div>
            <div class="contentBx">
              <h4>Ms. Sheeba Siddiqui</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={ashishJain} alt="Ashish Jain" />
            </div>
            <div class="contentBx">
              <h4>Mr. Ashish Jain</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sandeepKohli} alt="Sandeep Kohli" />
            </div>
            <div class="contentBx">
              <h4>Mr. Sandeep Kohli</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={vaibhavGupta} alt="Vaibhav Gupta" />
            </div>
            <div class="contentBx">
              <h4>Mr. Vaibhav Gupta</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={mdwasiullah} alt="Md Wasiullah" />
            </div>
            <div class="contentBx">
              <h4>Mr. Md Wasiullah</h4>
            </div>
          </div>
        </div>

       
        <div class="card"> 
          <div class="content">
            <div class="imgBx">
              <img src={dinesh} alt="Dinesh Gupta" />
            </div>
            <div class="contentBx">
              <h4>Mr. Dinesh Gupta</h4>
            </div>
          </div>
        </div>
        <div class="card"> 
          <div class="content">
            <div class="imgBx">
              <img src={amitKaushik} alt="Amit Kaushik" />
            </div>
            <div class="contentBx">
              <h4>Mr. Amit Kaushik</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={yasha} alt="Vaibhav Gupta" />
            </div>
            <div class="contentBx">
              <h4>Ms. Yasha Chaudhry</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={akshatbhtnagar} alt="Akshat Bhatnagar" />
            </div>
            <div class="contentBx">
              <h4>Mr. Akshat Bhatnagar</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={gauravMathur} alt="Gaurav Mathur" />
            </div>
            <div class="contentBx">
              <h4>Mr. Gaurav Mathur</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={adnanAbid} alt="Adnan Abid" />
            </div>
            <div class="contentBx">
              <h4>Mr. Adnan Abid</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={daanish} alt="Danish Farooqui" />
            </div>
            <div class="contentBx">
              <h4>Mr. Danish Farooqui</h4>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={abhishekjain} alt="Abhishek Jain" />
            </div>
            <div class="contentBx">
              <h4>Mr. Abhishek Jain</h4>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="content">
            <div class="imgBx">
              <img src={sandeepTaparia} alt="Abhishek Jain" />
            </div>
            <div class="contentBx">
              <h4>Mr. Sandeep Taparia</h4>
            </div>
          </div>
        </div>




      </div>
    </>
  );
};

export default CWC;
