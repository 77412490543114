import React from 'react'
import { useEffect } from 'react'
import './event1.css'
import event1 from './eventImg/event-1.webp'


const Event1 = () => {
  useEffect(() => {
    document.title = "IEC Workshop";
  }, []);
  return (
<>
<div className='eventheading'>

      <h1  className='text-center'>1st March 2025</h1>
      <h3 className='text-center'>IEC Workshop on Project Management</h3>
      </div>

    <div className='container' id='calanderevent'>
    <img src={event1} alt='pharmaConnect'/>
<button className='mb-4'><a href='https://ishraehq.in/Admin/CalenderEventMaster/Registration_new/Mplus0NSd42pGc=' target='_blank'>Register Now </a></button>


    </div>
</>
  )
}

export default Event1