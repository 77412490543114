import React from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './members.css'; 
// import ashishgupta from '../about/cwcimg/ashish-gupta.png'
// import priyankGarg from '../about/cwcimg/priyank-garg.png'
import sandeepGoel from '../about/cwcimg/sandeep-goel.png'
import walliullahSiddiquie from '../about/cwcimg/waliullah-siddiqui.png'
import samtaBajaj from '../about/cwcimg/samta-bjaj.png'
import sukhdaTandon from '../about/cwcimg/sukhda-tandon.png'
import varunJain from '../about/cwcimg/varun-jain.png'
import rajnsihAggarwal from '../about/cwcimg/rajnish-agarwal.png'
// import pradeep from '../about/cwcimg/pradeep.png'
// import gauravVasudev from '../about/cwcimg/gaurav-vasudev.png'
import robinChauhan from '../about/cwcimg/robin-chauhan.png'
import sheebaSiddiqui from '../about/cwcimg/sheeba-siddiqui.png'
// import priyankaJain from '../about/cwcimg/priyanka-jain.png'
import amitKaushik from '../about/cwcimg/amit-kaushilk.png'
import ruchiGrover from '../about/cwcimg/ruchi-grover.png'
import sandeepKohli from '../about/cwcimg/sandeep-kohli.png'
// import sanjeevSeth from '../about/cwcimg/sanjeev.png'
// import  pratimaSingh from '../about/cwcimg/pratima-singh.png'
// import  dinesh from '../about/cwcimg/dinesh.png'
// import  sushilkumar from '../about/cwcimg/sushil-kumar.png'
// import  gauravShorey from '../about/cwcimg/gourav-shoray.png'
// import  kartikSinghal from '../about/cwcimg/kartik-singhal.png'
// import  praveenThakur from '../about/cwcimg/praveen-thakur.png'
// import  vaibhavGupta from '../about/cwcimg/vaibhav-gupta.png'
// import  shurtiGoel from '../about/cwcimg/shruti-goel.png'
// import  pkGoel from '../about/cwcimg/pk-goel.png'
// import  pareshMishra from '../about/cwcimg/paresh-mishra.png'
import  akshatBhatnagr from '../about/cwcimg/akshat-bhtnagar.png'
// import  mdAdnan from '../about/cwcimg/md-adnan.png'
// import  anubhaGoel from '../about/cwcimg/anubha-goel.png'
import  gauravMathur from '../about/cwcimg/gaurav-mathur.png'
import  ashishJain from '../about/cwcimg/ashish-jain.png'
import  abhishekJain from '../about/cwcimg/abhishek-jain.png'
// import  priyankakulshreshtha from '../about/cwcimg/priyanka.png'
import  ravishankar from '../about/cwcimg/ravi-shankar.png'
import  mdWasiullah from '../about/cwcimg/mdwasiullah.png'
import  dineshGupta from '../about/cwcimg/dinesh.png'
import  yashaChaudhry from '../about/cwcimg/yasha-chaudhary.png'
import  adnanAbid from '../about/cwcimg/adnan-abid.png'
import  danish from '../about/cwcimg/danish.png'
import  sandeepTaparia from '../about/cwcimg/sandeep-taparia.png'







const teamMembers = [
    {
        img: ravishankar,
        name: 'Mr. Ravi Shankar Kumar',
        role: 'RD North 1',
    },
    {
        img: walliullahSiddiquie,
        name: 'Mr. Waliullah Siddiqui',
        role: 'President',
    },
    {
        img: sandeepGoel,
        name: 'Mr. Sandeep Goel',
        role: 'Past President',
    },
    {
        img: sukhdaTandon,
        name: 'Ms. Sukhda Tandon',
        role: 'President - Elect',
    },
    {
        img: ruchiGrover,
        name: 'Dr. Ruchi Grover',
        role: 'Secretary',
    },
    {
        img: robinChauhan,
        name: 'Mr. Robin Chauhan',
        role: 'Treasurer',
    },
    {
        img: varunJain,
        name: 'Dr. Varun Jain',
        role: 'CWC Member',
    },
    {
        img: rajnsihAggarwal,
        name: 'Mr. Rajnish Aggarwal',
        role: 'CWC Member',
    },
    {
        img: samtaBajaj,
        name: 'Ms. Samta Bajaj',
        role: 'CWC Member',
    },
    {
        img: sheebaSiddiqui,
        name: 'Ms. Sheeba Siddiqui',
        role: 'CWC Member',
    },
    {
        img: ashishJain,
        name: 'Mr. Ashish Jain',
        role: 'CWC Member',
    },
    {
        img: sandeepKohli,
        name: 'Mr. Sandeep Kohli',
        role: 'CWC Member',
    },
    {
        img: mdWasiullah,
        name: 'Mr. Md Wasiullah',
        role: 'CWC Member',
    },
    {
        img: dineshGupta,
        name: 'Mr. Dinesh Gupta',
        role: 'CWC Member',
    },
   
    {
        img: amitKaushik,
        name: 'Mr. Amit Kaushik',
        role: 'CWC Member',
    },
    {
        img: yashaChaudhry,
        name: 'Ms. Yasha Chaudhry',
        role: 'CWC Member',
    },
    {
        img: akshatBhatnagr,
        name: 'Mr. Akshat Bhatnagar',
        role: 'CWC Member',
    },
    {
        img: gauravMathur,
        name: 'Mr. Gaurav Mathur',
        role: 'CWC Member',
    },
    {
        img: adnanAbid,
        name: 'Mr. Adnan Abid',
        role: 'CWC Member',
    },
    {
        img: danish,
        name: 'Mr. Danish Farooqui',
        role: 'CWC Member',
    },
    {
        img: abhishekJain,
        name: 'Mr. Abhishek Jain',
        role: 'CWC Member',
    },
    {
        img: sandeepTaparia,
        name: 'MR. Sandeep Taparia',
        role: 'CWC Member',
    },
    
];

const Members = () => {
    const settings = {
        slidesToShow: 4,
        speed: 300,
        centerPadding: '60px',
        prevArrow: <button className="slick-prev"><FontAwesomeIcon icon={faArrowLeft} /></button>,
        nextArrow: <button className="slick-next"><FontAwesomeIcon icon={faArrowRight} /></button>,
        autoplay: true, // Enable auto-sliding
        autoplaySpeed: 2000, // Set speed to 2 seconds
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="section">
            <div className="title">
                <h1>CWC TEAM MEMBERS</h1>
            </div>
            <Slider {...settings} className="team-members">
                {teamMembers.map((member, index) => (
                    <div className="member-card1" key={index}>
                        <img src={member.img} alt={member.name} />
                        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.507)'}} className="content">
                            <h2>{member.name}</h2>
                            <h3>{member.role}</h3>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Members;
